import { useState } from 'react';
import './about.scss';

export default function About() {
  const [showAbout, setShowAbout] = useState(false);
  return (
    <div className={`about ${showAbout ? 'about--open' : ''}`}>
      <div className="about__toggle" onClick={() => setShowAbout(!showAbout)}>
        <button className="about__toggle">
          {!showAbout ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px">
              {' '}
              <path d="M25,2C12.297,2,2,12.297,2,25s10.297,23,23,23s23-10.297,23-23S37.703,2,25,2z M25,11c1.657,0,3,1.343,3,3s-1.343,3-3,3 s-3-1.343-3-3S23.343,11,25,11z M29,38h-2h-4h-2v-2h2V23h-2v-2h2h4v2v13h2V38z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="about__close"
              viewBox="0 0 30 30"
              width="30px"
              height="30px"
            >
              {' '}
              <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" />
            </svg>
          )}
          <span className="sr-only">About</span>
        </button>
      </div>
      {showAbout && (
        <div className="about__details">
          <p>
            A fun little jolly from <a href="https://www.almaprojects.studio">Alma projects</a>. Very much a work in
            progress so <a href="mailto:mailto:contact@almaprojects.studio">get in touch</a> with any feedback or
            suggestions.
          </p>
        </div>
      )}
    </div>
  );
}
